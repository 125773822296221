import request from '@/tools/request';
import { Res } from '../types/common';
import { HomeFunc } from '../types/home';

export const getInstallStatus: HomeFunc = loading => {
  return request({
    url: '/criteo-index-status.html',
    loading,
  }) as Promise<Res<{ status: boolean }>>;
};

export const uninstall: HomeFunc = loading => {
  return request({
    url: '/criteo-index-uninstall.html',
    loading,
  }) as Promise<Res<{ status: boolean }>>;
};
export const install: HomeFunc = loading => {
  return request({
    url: '/criteo-index-install.html',
    loading,
  }) as Promise<Res<{ status: boolean }>>;
};

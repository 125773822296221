
import { onMounted, ref } from 'vue';
import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import MImagePreview from '@/components/imagePreview/ImagePreview.vue';
import MFormSkeleton from '@/components/formSkeleton/FormSkeleton.vue';
import { getInstallStatus, install, uninstall } from '../api/home';

import homePreview1 from '../statics/img/home1.png';
import homePreview2 from '../statics/img/home2.png';
import homePreview3 from '../statics/img/home3.png';
import homePreview4 from '../statics/img/home4.png';
import { ElMessage, ElButton } from 'element-plus';
export default {
  name: 'Home',
  components: {
    'm-page-header': MPageHeader,
    'm-image-preview': MImagePreview,
    MFormSkeleton,
    ElButton,
  },
  setup() {
    const loading = ref(true);
    const isInstall = ref(false); // 是否已安装
    const btnLoading = ref(false);

    // 获取安装状态
    const setInstallStatus = async () => {
      const { status, data } = await getInstallStatus(loading);

      if (!status) return;
      isInstall.value = !!data?.status;
    };

    // 卸载 / 安装 插件
    const setPlugin = async () => {
      if (isInstall.value) {
        const res = await uninstall(btnLoading);
        if (!res.status) return;

        ElMessage.success('卸载成功');
        isInstall.value = !isInstall.value;
      } else {
        const res = await install(btnLoading);
        if (!res.status) return;
        ElMessage.success('安装成功');
        isInstall.value = !isInstall.value;
      }
    };

    onMounted(() => {
      setInstallStatus();
    });

    return {
      homePreview1,
      homePreview2,
      homePreview3,
      homePreview4,
      setInstallStatus,
      setPlugin,
      isInstall,
      btnLoading,
      loading,
    };
  },
};
